.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3; /* Светлый цвет бублика */
    border-top: 5px solid #4caf50; /* Зеленый цвет заполнения */
    border-radius: 50%;
    animation: spin 1s linear infinite; /* Анимация вращения */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}